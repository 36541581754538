// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-hero-js": () => import("./../../../src/pages/hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-placements-js": () => import("./../../../src/pages/international-placements.js" /* webpackChunkName: "component---src-pages-international-placements-js" */),
  "component---src-pages-international-team-js": () => import("./../../../src/pages/international-team.js" /* webpackChunkName: "component---src-pages-international-team-js" */),
  "component---src-pages-query-form-js": () => import("./../../../src/pages/query-form.js" /* webpackChunkName: "component---src-pages-query-form-js" */),
  "component---src-pages-skilling-js": () => import("./../../../src/pages/skilling.js" /* webpackChunkName: "component---src-pages-skilling-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test-index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

